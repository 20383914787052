export default {
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  getServe() {
    if (process.env.NODE_ENV === "production") {
      return "https://dgfmapi.minedu.gob.bo";
    } else {
      return "http://localhost:4000";
    }
  },
  getBase() {
    return this.getServe() + "/web/";
  },
  getHeader() {
    return {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.getTokenWeb(),
      },
    };
  },
  getTokenMap() {
    //------TOKEN DE MAPBOX-----
    //return "pk.eyJ1Ijoic2lnZWUiLCJhIjoiY2p5c3FmOWgxMG44ajNsbG0weTdmbHFqMCJ9.xzWqmSJjMqpM86r1Kf1jhg";  //Token ANTIGUO ---no muestra el mapa---

    return "pk.eyJ1IjoiZGdmbSIsImEiOiJjbDllbjB3M2QwMnB0M3VwM3Y3b3dndmgxIn0.i22M60cUqZpsMSGqhNId2g"; // Token ACTUAL ---funciona para mapas---
  },
  getTokenWeb() {
    return "oyJhbGciOiJIUzUxMiIsInR5cCI6Ik9URUMifQ.eyJzdWIiOiIxNjIzNDA2NDc5NjIyMCIsIm5hbWUiOiJZeHVKKnpHcTBVbVV1YyNxV2FzcSIsImlhdCI6OTg5MjM1MTYyMzkwMjJ9.w4IyJ1e_evWB8p3IumcJKHTrjAI5bmGwDGo7_VJtb0lferWyF9bRW7V2cRP8UVtP0GJoWivD1_ZWMs4wu4jzxQ";
  },
  setInstitutionId(institucion_id) {
    let strencode = this.encode(institucion_id.toString());
    return sessionStorage.setItem("esfm", strencode);
  },
  getInstitutionId() {
    return sessionStorage.getItem("esfm")
      ? this.decode(sessionStorage.getItem("esfm"))
      : null;
  },
  removeInstitutionId() {
    return sessionStorage.removeItem("esfm");
  },
  setNewsId(news_id) {
    let strencode = this.encode(news_id.toString());
    return sessionStorage.setItem("hydwx", strencode);
  },
  getNewsId() {
    return sessionStorage.getItem("hydwx")
      ? this.decode(sessionStorage.getItem("hydwx"))
      : null;
  },
  removeNewsId() {
    return sessionStorage.removeItem("hydwx");
  },

  // public method for encoding
  encode(input) {
    let output = "";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
    /* if (input) {
      input.toString();
    } */

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 61;
      } else if (isNaN(chr3)) {
        enc4 = 61;
      }
      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }
    return output;
  },

  // public method for decoding
  decode(input) {
    let output = "";
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9]/g, "");

    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 61) {
        output = output + String.fromCharCode(chr2);
      }

      if (enc4 != 61) {
        output = output + String.fromCharCode(chr3);
      }
    }
    return output;
  },
};
